import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../globalContext';
import {useNavigate} from 'react-router-dom';
import {Input, Modal, message} from 'antd';
import axiosInstance from '../../axiosInstance';
import trophy from '../../static/media/trophy.png';
import wifi from '../../static/media/wifi.png';
import st from '../../images/ludo/gold-medal.png';
import trophyji from '../../static/media/trophy.png';
import secure02 from '../../images/ludo/letter-s.png';
import wifi02 from "../../images/ludo/system-solid-102-wifi.gif"





const Game1 = () => {
  const user = useContext(UserContext);
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roomCode1, setRoomCode1] = useState();
  const [gameValue, setGameValue] = useState(false);
  const [walletAmount, setWalletAmount] = useState();
  const [runingGame, setRuningGame] = useState();
  const [runingGameId, setRuningGameId] = useState();
  const [runing, setRuning] = useState(false);
  const [playWait, setPlayWait] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user.userId == null) {
        window.location.reload(true);
      }
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getPayment();
    getRuningGame();
  };
  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAdd = () => {
    setIsModalOpen(false);
  };
  const getPayment = () => {
    axiosInstance.get(`wallet/${user.userId}`).then(response => {
      setWalletAmount(
        response.data.data.winningAmount + response.data.data.depositeAmount,
      );
    });
  };

  const getRuningGame = () => {
    axiosInstance.get(`game/runing/${user.userId}`).then(response => {
      if (response.data.data !== null) {
        setRuning(true);
        setRuningGame([response.data.data]);
      } else {
        if (response.data.data === null) {
          axiosInstance.get(`game/runing1/${user.userId}`).then(response1 => {
            if (response1.data.data !== null) {
              setRuning(true);
              setRuningGame([response1.data.data]);
            } else {
              if (response1.data.data === null) {
                axiosInstance
                  .get(`game/runing2/${user.userId}`)
                  .then(response2 => {
                    if (response2.data.data !== null) {
                      setRuning(true);
                      setRuningGame([response2.data.data]);
                    } else {
                      if (response2.data.data === null) {
                        axiosInstance
                          .get(`game/runing3/${user.userId}`)
                          .then(response3 => {
                            if (response3.data.data !== null) {
                              setRuning(true);
                              setRuningGame([response3.data.data]);
                            } else {
                              if (response3.data.data === null) {
                                setRuning(false);
                                getRuningGame();
                              }
                            }
                          });
                      }
                    }
                  });
              }
            }
          });
        }
      }

      //setWalletAmount(response.data.data.amount);
    });
  };
  const onPlayGame = e => {
    if (walletAmount > e-1) {
      setPlayWait(e);
      axiosInstance.get(`game/runing/${user.userId}`).then(response => {
        if (response.data.data !== null) {
          setRuning(true);
          setRuningGame([response.data.data]);
          message.error(
            'You are already in the game.please complete the game first. And play again...!',
          );
          setPlayWait(false);
        } else {
          if (response.data.data === null) {
            axiosInstance.get(`game/runing1/${user.userId}`).then(response1 => {
              if (response1.data.data !== null) {
                setRuning(true);
                setRuningGame([response1.data.data]);
                message.error(
                  'You are already in the game.please complete the game first. And play again...!',
                );
          setPlayWait(false)

              } else {
                if (response1.data.data === null) {
                  axiosInstance
                    .get(`game/runing2/${user.userId}`)
                    .then(response2 => {
                      if (response2.data.data !== null) {
                        setRuning(true);
                        setRuningGame([response2.data.data]);
                        message.error(
                          'You are already in the game.please complete the game first. And play again...!',
                        );
          setPlayWait(false)

                      } else {
                        if (response2.data.data === null) {
                          axiosInstance
                            .get(`game/runing3/${user.userId}`)
                            .then(response3 => {
                              if (response3.data.data !== null) {
                                setRuning(true);
                                setRuningGame([response3.data.data]);
                                message.error(
                                  'You are already in the game.please complete the game first. And play again...!',
                                );
          setPlayWait(false)

                              } else {
                                if (response3.data.data === null) {
                                  setRuning(false);
                                  onPlayGame2(e);
                                }
                              }
                            });
                        }
                      }
                    });
                }
              }
            });
          }
        }

        //setWalletAmount(response.data.data.amount);
      });
    } else {
      message.error('Insufficient chips...!');
      navigate(`/Recharge`);
    }
  };
  
 

  const onPlayGame1 = e => {
    var ii ;
    Modal.confirm ({
      
      title: <div>
      
        <div className='mt-0'> <div className="shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
        Enter Classic v2 Room Code
        </div>
        <div class="card-body">
          <div className='pt-0'>
          <div role="alert" class="mb-2 fade text-center alert alert-primary addextra show text-dark">
              कृपया सही रूम कोड <span className='text-danger'>(ROOM CODE)</span> डाले ।
              </div>
          </div>
        {/* <label>Enter Classic v2 Room Code</label> */}
        <Input placeholder='Enter Room Code' className='shadowmyadd' type='Number' onChange={(el) => {
       ii = (el.target.value)
        }} />
        
          <div>
            <div className='pt-2 m-0'>
             
              <div role="alert" class="m-0  fade alert alert-primary addextra show text-dark">
              NOTE:- यदि आपके <span className='text-danger'>ROOM CODE</span> गलत पाया जाता है तो आपका गेम हारा हुआ माना जाएगा ।

             <p className='pt-1 m-0'> टेबल लगने के बाद आप <span className='text-danger'>ROOM CODE</span> चेंज नहीं कर सकते ।</p>
              </div>
             
            
            </div>
          </div>
        </div>
      </div></div>
      </div>,
      okText: "Yes",
      okType: "succes",
      cancelText: "No",
      onOk() {
        if(ii === undefined) {
          setPlayWait(false);
          message.error("please provide a valid ROOM CODE")
        }
        else {
          const data = {
            gameDate: new Date(),
            player1: user.userId,
            status: 'created',
            gameAmount: e,
            playerCount: 1,
            winningAmount: e * 2 - e * 0.1,
            roomCode: ii,
            debitAmount: false,
          };
          if (
            data.player1 !== undefined &&
            data.gameAmount !== undefined &&
            data.winningAmount !== undefined &&
            data.status !== undefined &&
            data.roomCode !== undefined &&
            data.roomCode !== '' &&
            data.status !== '' &&
            data.winningAmount !== '' &&
            data.gameAmount !== '' &&
            data.player1 !== ''
          ) {
            axiosInstance.post('/game', data).then(res => {
              if (res.data && res.data.responseCode === -1) {
                message.error('Record Already Exists');
              } else if (res.data && res.data.responseCode === 1) {
                navigate(
                  `/GameRoom/${encodeURIComponent(JSON.stringify(res.data.data))}`,
                  {replace: true},
                );
              } else message.error('Something wrong. Please try again...!');
            });
          } else {
            message.error(
              'Please fill out all required fields. And submit again...!',
            );
          }
        }
      
    },
    CancelText: "Yes",
    CancelType: "Danger",
    cancelText: "No",
    onCancel() {
      setPlayWait(false);

    },
  });
  };

  const onPlayGame2 = e => {
    // if(walletAmount>e){

    axiosInstance.post('/game/user/status', {gameAmount: e}).then(res => {
      if (res.data.data === null) {
        onPlayGame1(e);
      } else {
        axiosInstance
          .put(`/game/${res.data.data._id}`, {
            status: 'joined',
            playerCount: 2,
            player2: user.userId,
            gameJoinedDate:new Date()
          })
          .then(response => {
            if (response.data.responseCode === 1) {
              message.success('Table created successfully');
              axiosInstance.get(`/game/${res.data.data._id}`).then(response => {
                navigate(
                  `/GameRoom/${encodeURIComponent(
                    JSON.stringify(response.data.data),
                  )}`,
                  {replace: true},
                );
              });
            }
          });
      }
    });
    //   }
    // else {
    //   message.error("Insufficient chips...!");
    //   navigate(`/Recharge`);

    // }
  };

  const onViewGame = e => {
    navigate(`/GameRoom/${encodeURIComponent(JSON.stringify(runingGame[0]))}`, {
      replace: true,
    });
  };

  return (
    <div className="realludokingsize">
      <div class=" col-12 mx-auto g-0 iframe-sec ">
        
        <div class="d-flex flex-column">
          <ul id="playable-challange-list" class="m-0 px-2">
            {runing && (
              <li class="p-0 overflow-hidden appear-from-left ">

                <div className='p-agggg'>
                <div className="my-2 card shadow02" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-center card-header view">
             
                  <span className="d-flex align-items-center">

                  <img className="wifiji me-2" src={wifi02} alt="" />
                 <b> Currently Running</b>
                 <img className="wifiji ms-2" src={wifi02} alt="" />

                  </span>
                
                 
                </div>
                <div className="d-flex align-items-center justify-content-between card-body myadd">
                
                <div className="d-flex align-items-center flex-grow-1">
             
             <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Prize pool</h6>
                
                </div>
                
                <div className="d-flex align-items-center">
                <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Running</h6>
                </div>
              </div>
                <div className="d-flex align-items-center justify-content-between card-body">
                
                  <div className="d-flex align-items-center flex-grow-1">
               
                    <span
                      className="fw-semibold text-truncate text-black"
                      style={{ fontSize: '120%'}}>
                      &#8377;{runingGame[0].winningAmount}
                    </span>
                  </div>
                  
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                      {playWait !== "50" &&<button className="btn btn-primary playChallange btn-sm fw-bold  " onClick={() => onViewGame('50')}>View   </button> }
                      {playWait==="50" &&  <div class="spinner-border text-success" role="status">
  
  </div>}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-muted myfooter view">
                <div className='d-flex w-100'> 
                    <p className='m-0 '><img className="wifiji me-1" src={st} alt="" />&#8377;{runingGame[0].winningAmount}</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={trophyji} alt="" />50%</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={secure02} alt="" />single</p>
                  

                  </div>
  </div>
                
              </div>
                </div>
             
            </li>
              // <li class="p-0 overflow-hidden appear-from-left">
              //   <div className="my-2 card" style={{borderColor: 'gray'}}>
              //     <div className="d-flex align-items-center justify-content-between card-header">
              //       <span className="d-flex align-items-center">
              //         Currently Playing: <h3 className="colorpoint">562 </h3>
              //       </span>
              //       <img className="wifiji" src={wifi} alt="" />
              //     </div>
              //     <div className="d-flex align-items-center justify-content-between card-body">
              //       <div className="d-flex align-items-center flex-grow-1">
              //         <img
              //           src={trophy}
              //           alt="avatar"
              //           style={{height: '30px', width: '30px'}}
              //         />
              //         <span
              //           className="fw-semibold text-truncate text-success"
              //           style={{width: '100px', fontSize: '120%'}}>
              //           {runingGame[0].gameAmount}
              //         </span>
              //       </div>
              //       <div className="d-flex align-items-center">
              //         <div className="hstack gap-2">
              //           <button
              //             className="btn btn-primary playChallange btn-sm"
              //             onClick={() => onViewGame('50')}>
              //             View
              //           </button>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </li>
            )}
            <div className="separator mt-3 mb-3">
              <hr className="line01" />
              <img
                src={trophy}
                alt="WinCupImg"
                style={{height: '20px', width: '20px'}}
              />
              &nbsp; <h2 className="che">Challenges</h2> &nbsp;
              <img
                src={trophy}
                alt="WinCupImg"
                style={{height: '20px', width: '20px'}}
              />
              <hr className="line01" />
            </div>

            <li class="p-0 overflow-hidden appear-from-left ">
              <div className=' p-agggg'>
              <div className="my-2 card shadow02" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-center card-header">
             
                  <span className="d-flex align-items-center">
                  <img className="wifiji me-2" src={wifi} alt="" />
                    Players: <h3 className="colorpoint ">122 </h3>
                  </span>
                
                 
                </div>
                <div className="d-flex align-items-center justify-content-between card-body myadd">
                
                <div className="d-flex align-items-center flex-grow-1">
             
             <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Prize pool</h6>
                
                </div>
                
                <div className="d-flex align-items-center">
                <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Entry</h6>
                </div>
              </div>
                <div className="d-flex align-items-center justify-content-between card-body">
                
                  <div className="d-flex align-items-center flex-grow-1">
               
                    <span
                      className="fw-semibold text-truncate text-black"
                      style={{ fontSize: '120%'}}>
                      &#8377;{50*2-50*0.1}
                    </span>
                  </div>
                  
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                      {playWait !== "50" &&<button className="btn btn-success playChallange btn-sm mycoustm fw-bold " onClick={() => onPlayGame("50")}>&#8377;   50   </button> }
                      {playWait==="50" &&  <div class="spinner-border text-success" role="status">
  
  </div>}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-muted myfooter">
                <div className='d-flex w-100'> 
                    <p className='m-0 '><img className="wifiji me-1" src={st} alt="" />&#8377;{50*2-50*0.1}</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={trophyji} alt="" />50%</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={secure02} alt="" />single</p>
                  

                  </div>
  </div>
                
              </div>
              </div>
             
            </li>
            <li class="p-0 overflow-hidden appear-from-left">
            <div className=' p-agggg'>
              <div className="my-2 card shadow02" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-center card-header">
             
                  <span className="d-flex align-items-center">
                  <img className="wifiji me-2" src={wifi} alt="" />
                    Players: <h3 className="colorpoint ">388 </h3>
                  </span>
                
                 
                </div>
                <div className="d-flex align-items-center justify-content-between card-body myadd">
                
                <div className="d-flex align-items-center flex-grow-1">
             
             <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Prize pool</h6>
                
                </div>
                
                <div className="d-flex align-items-center">
                <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Entry</h6>
                </div>
              </div>
                <div className="d-flex align-items-center justify-content-between card-body">
                
                  <div className="d-flex align-items-center flex-grow-1">
               
                    <span
                      className="fw-semibold text-truncate text-black"
                      style={{ fontSize: '120%'}}>
                      &#8377;{100*2-100*0.1}
                    </span>
                  </div>
                  
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                      {playWait !== "100" &&<button className="btn btn-success playChallange btn-sm mycoustm fw-bold " onClick={() => onPlayGame("100")}>&#8377;   100   </button> }
                      {playWait==="100" &&  <div class="spinner-border text-success" role="status">
  
  </div>}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-muted myfooter">
                <div className='d-flex w-100'> 
                    <p className='m-0 '><img className="wifiji me-1" src={st} alt="" />&#8377;{100*2-100*0.1}</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={trophyji} alt="" />50%</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={secure02} alt="" />single</p>
                  

                  </div>
  </div>
                
              </div>
              </div>
            </li>
            <li class="p-0 overflow-hidden appear-from-left">
            <div className=' p-agggg'>
              <div className="my-2 card shadow02" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-center card-header">
             
                  <span className="d-flex align-items-center">
                  <img className="wifiji me-2" src={wifi} alt="" />
                    Players: <h3 className="colorpoint ">360 </h3>
                  </span>
                
                 
                </div>
                <div className="d-flex align-items-center justify-content-between card-body myadd">
                
                <div className="d-flex align-items-center flex-grow-1">
             
             <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Prize pool</h6>
                
                </div>
                
                <div className="d-flex align-items-center">
                <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Entry</h6>
                </div>
              </div>
                <div className="d-flex align-items-center justify-content-between card-body">
                
                  <div className="d-flex align-items-center flex-grow-1">
               
                    <span
                      className="fw-semibold text-truncate text-black"
                      style={{ fontSize: '120%'}}>
                      &#8377;{200*2-200*0.1}
                    </span>
                  </div>
                  
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                      {playWait !== "200" &&<button className="btn btn-success playChallange btn-sm mycoustm fw-bold " onClick={() => onPlayGame("200")}>&#8377;   200   </button> }
                      {playWait==="200" &&  <div class="spinner-border text-success" role="status">
  
  </div>}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-muted myfooter">
                <div className='d-flex w-100'> 
                    <p className='m-0 '><img className="wifiji me-1" src={st} alt="" />&#8377;{200*2-200*0.1}</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={trophyji} alt="" />50%</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={secure02} alt="" />single</p>
                  

                  </div>
  </div>
                
              </div>
              </div>
            </li>
            <li class="p-0 overflow-hidden appear-from-left">
            <div className=' p-agggg'>

              <div className="my-2 card shadow02" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-center card-header">
             
                  <span className="d-flex align-items-center">
                  <img className="wifiji me-2" src={wifi} alt="" />
                    Players: <h3 className="colorpoint ">400 </h3>
                  </span>
                
                 
                </div>
                <div className="d-flex align-items-center justify-content-between card-body myadd">
                
                <div className="d-flex align-items-center flex-grow-1">
             
             <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Prize pool</h6>
                
                </div>
                
                <div className="d-flex align-items-center">
                <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Entry</h6>
                </div>
              </div>
                <div className="d-flex align-items-center justify-content-between card-body">
                
                  <div className="d-flex align-items-center flex-grow-1">
               
                    <span
                      className="fw-semibold text-truncate text-black"
                      style={{ fontSize: '120%'}}>
                      &#8377;{500*2-500*0.1}
                    </span>
                  </div>
                  
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                      {playWait !== "500" &&<button className="btn btn-success playChallange btn-sm mycoustm fw-bold " onClick={() => onPlayGame("500")}>&#8377;   500   </button> }
                      {playWait==="500" &&  <div class="spinner-border text-success" role="status">
  
  </div>}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-muted myfooter">
                <div className='d-flex w-100'> 
                    <p className='m-0 '><img className="wifiji me-1" src={st} alt="" />&#8377;{500*2-500*0.1}</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={trophyji} alt="" />50%</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={secure02} alt="" />single</p>
                  

                  </div>
  </div>
                
              </div>
              </div>
            </li>
            <li class="p-0 overflow-hidden appear-from-left">
            <div className=' p-agggg'>

              <div className="my-2 card shadow02" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-center card-header">
             
                  <span className="d-flex align-items-center">
                  <img className="wifiji me-2" src={wifi} alt="" />
                    Players: <h3 className="colorpoint ">260 </h3>
                  </span>
                
                 
                </div>
                <div className="d-flex align-items-center justify-content-between card-body myadd">
                
                <div className="d-flex align-items-center flex-grow-1">
             
             <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Prize pool</h6>
                
                </div>
                
                <div className="d-flex align-items-center">
                <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Entry</h6>
                </div>
              </div>
                <div className="d-flex align-items-center justify-content-between card-body">
                
                  <div className="d-flex align-items-center flex-grow-1">
               
                    <span
                      className="fw-semibold text-truncate text-black"
                      style={{ fontSize: '120%'}}>
                      &#8377;{1000*2-1000*0.1}
                    </span>
                  </div>
                  
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                      {playWait !== "1000" &&<button className="btn btn-success playChallange btn-sm mycoustm fw-bold " onClick={() => onPlayGame("1000")}>&#8377;   1000   </button> }
                      {playWait==="1000" &&  <div class="spinner-border text-success" role="status">
  
  </div>}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-muted myfooter">
                <div className='d-flex w-100'> 
                    <p className='m-0 '><img className="wifiji me-1" src={st} alt="" />&#8377;{1000*2-1000*0.1}</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={trophyji} alt="" />50%</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={secure02} alt="" />single</p>
                  

                  </div>
  </div>
                
              </div>
              </div>
            </li>
            <li class="p-0 overflow-hidden appear-from-left">
            <div className=' p-agggg mb-3'>

              <div className="my-2 card shadow02" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-center card-header">
             
                  <span className="d-flex align-items-center">
                  <img className="wifiji me-2" src={wifi} alt="" />
                    Players: <h3 className="colorpoint ">122 </h3>
                  </span>
                
                 
                </div>
                <div className="d-flex align-items-center justify-content-between card-body myadd">
                
                <div className="d-flex align-items-center flex-grow-1">
             
             <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Prize pool</h6>
                
                </div>
                
                <div className="d-flex align-items-center">
                <h6 className="fw-semibold  text-secondary m-0 mt-2"
                    style={{ fontSize: '100%'}}>Entry</h6>
                </div>
              </div>
                <div className="d-flex align-items-center justify-content-between card-body">
                
                  <div className="d-flex align-items-center flex-grow-1">
               
                    <span
                      className="fw-semibold text-truncate text-black"
                      style={{ fontSize: '120%'}}>
                      &#8377;{5000*2-5000*0.1}
                    </span>
                  </div>
                  
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                      {playWait !== "5000" &&<button className="btn btn-success playChallange btn-sm mycoustm fw-bold " onClick={() => onPlayGame("5000")}>&#8377;   5000   </button> }
                      {playWait==="5000" &&  <div class="spinner-border text-success" role="status">
  
  </div>}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-muted myfooter">
                <div className='d-flex w-100'> 
                    <p className='m-0 '><img className="wifiji me-1" src={st} alt="" />&#8377;{5000*2-5000*0.1}</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={trophyji} alt="" />50%</p>
                    <p className='m-0 ms-2'><img className="wifiji me-1" src={secure02} alt="" />single</p>
                  

                  </div>
  </div>
                
              </div>
              </div>
            </li>

            
           {/* <li class="p-0 overflow-hidden appear-from-left">
              <div className="my-2 card" style={{borderColor: 'gray'}}>
                <div className="d-flex align-items-center justify-content-between card-header">
                  <span className="d-flex align-items-center">
                    Currently Playing: <h3 className="colorpoint">84 </h3>
                  </span>
                  <img className="wifiji" src={wifi} alt="" />
                </div>
                <div className="d-flex align-items-center justify-content-between card-body">
                  <div className="d-flex align-items-center flex-grow-1">
                    <img
                      src={trophy}
                      alt="avatar"
                      style={{height: '30px', width: '30px'}}
                    />
                    <span
                      className="fw-semibold text-truncate text-success"
                      style={{width: '100px', fontSize: '120%'}}>
                      Rs 5000
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="hstack gap-2">
                    {playWait !== "5000" &&<button className="btn btn-primary playChallange btn-sm" onClick={() => onPlayGame("5000")}>Play</button> }
                      {playWait==="5000" && <div class="spinner-border text-success" role="status">
  
</div>}
                    </div>
                  </div>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Game1;
