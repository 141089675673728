

import React, { useContext, useEffect, useState,useRef } from "react";
import {Input, message, Table, Modal,Select, Space } from "antd";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Button } from "react-bootstrap";
import axiosInstance from "../../axiosInstance";
import trns from "../../static/media/Add-a-heading-1-min.png";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
const History = (props) => {


  const user = useContext(UserContext);
  let navigate = useNavigate();
  const [PaymentData1, setPaymentData1] = useState([]);
  const [PaymentData2, setPaymentData2] = useState([]);
  const [gameData, setGameData] = useState(null);
  const [ShowT, setShowT] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, pickupService) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(pickupService);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (pickupService) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${pickupService}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, pickupService)
          }
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, pickupService)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(pickupService);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[pickupService]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === pickupService ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {

    let mounted = true;
    if (mounted) {


      if (user.userId == null) {
        window.location.reload(true);
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getWPayment();
    getRPayment();
    getGame();
  }
  const getRPayment = () => {
    axiosInstance.get(`/payment/user/${user.userId}`).then((response) => {
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );
      setPaymentData2(sortedData);
    });
  };
  const getWPayment = () => {
    axiosInstance.get(`/withdraw/user/${user.userId}`).then((response) => {
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );
      setPaymentData1(sortedData);
    });
  };
var gameC = []
var getG = 1 
  const getGame = () =>{
    getG = getG +1
   if(getG === 2) {
    axiosInstance.get(`/game/runing4/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
      getGame2()
    }
    else{
      getGame3();
     }
    });
    
   }
  }
var getG2 = 1 

  const getGame2 = () =>{
    getG2 = getG2 +1
   if(getG2 === 2) {
    axiosInstance.get(`/game/runing5/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
      getGame3();
      }
      else{
        getGame3();
       }
    });

   }
  }
  var getG3 = 1 

  const getGame3 = () =>{
    getG3 = getG3 +1
   if(getG3 === 2) {
    axiosInstance.get(`/game2/runing4/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
        const sortedData = [...response.data.data].sort(
          (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
        );

        gameC.push(...sortedData)
        getGame4();
      }
     else{
      getGame4();
     }

    });
    
   }
  }
  var getG4 = 1 

  const getGame4 = async() =>{
    getG4 = getG4 +1
   if(getG4 === 2) {
   await axiosInstance.get(`/game2/runing5/${user.userId}`).then((response) => {
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
    console.log("sorted Game",sortedData)

      gameC.push(...sortedData)

    });
    const sortedData = [...gameC].sort(
      (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
    );
    setGameData(sortedData)
   }
  }
  const columns3 = [
   
      
     
      {
        title: "Game Date",
        render: (row) => {
            return(
              <div style={{alignItems:"center",textAlign:"center"}}>
              {row && <div >{moment(row.gameDate).format("DD-MM-yyyy")}</div>}
              </div>
            );
          },
      },
      {
        title: "Room Code",
      //   dataIndex: "roomCode",
      // key: "roomCode",
      ...getColumnSearchProps("roomCode"),

      render: (row) => {
        return row.roomCode ? (
          <div style={{alignItems:"center",textAlign:"center"}}>

          { row&& <b style={{color:"green"}}>{row.roomCode}</b>}<br/>
        
            </div>
        ) : (
          <div> </div>
        );
      },
      },
      {
        title: "Table Amount",
        // dataIndex: "gameAmount",
        // key: "gameAmount",
        render: (row) => {
                  return row.gameAmount ? (
                    <div style={{alignItems:"center",textAlign:"center"}}>
          
                    { row&& <b style={{color:"#0d6efd"}}>₹{row.gameAmount}</b>}<br/>
                  
                      </div>
                  ) : (
                    <div> </div>
                  );
                },
      },
      {
        title: "Status",
        // dataIndex: "status",
        // key: "status",
        render: (row) => {
          if(row.winner==="player1"){
            if(row.player1._id===user.userId){
              row.status="Win";
              return row.status ? (
                    
                <div style={{alignItems:"center",textAlign:"center"}}>
      
                { row&& <b style={{color:"green"}}>{row.status}</b>}<br/>
              
                  </div>
              ) : (
                <div> </div>
              );
            }
            else{
              row.status="Lost";
              return row.status ? (
                    
                <div style={{alignItems:"center",textAlign:"center"}}>
      
                { row&& <b style={{color:"red"}}>{row.status}</b>}<br/>
              
                  </div>
              ) : (
                <div> </div>
              );
            }
          }
          else{
            if(row.winner==="player2"){
              if(row.player2._id===user.userId){
                row.status="Win";

                return row.status ? (
                    
                  <div style={{alignItems:"center",textAlign:"center"}}>
        
                  { row&& <b style={{color:"green"}}>{row.status}</b>}<br/>
                
                    </div>
                ) : (
                  <div> </div>
                );
  
              }
              else{
                row.status="Lost";
                return row.status ? (
                    
                  <div style={{alignItems:"center",textAlign:"center"}}>
        
                  { row&& <b style={{color:"red"}}>{row.status}</b>}<br/>
                
                    </div>
                ) : (
                  <div> </div>
                );
              }
            }
            else{
              if(row.winner==="tie"){
                row.status="Tie";

                return row.status ? (
                    
                  <div style={{alignItems:"center",textAlign:"center"}}>
        
                  { row&& <b style={{color:"green"}}>{row.status}</b>}<br/>
                
                    </div>
                ) : (
                  <div> </div>
                );
  
              }
              else{
                row.status="Inreview";
                return row.status ? (
                      
                  <div style={{alignItems:"center",textAlign:"center"}}>
        
                  { row&& <b style={{color:"blue"}}>{row.status}</b>}<br/>
                
                    </div>
                ) : (
                  <div> </div>
                );
              }
            }
          }
                 
                },
      },
   
  ];
  const columns1 = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    // {
    //     title: "Name",
    //     render: (row) => {
    //         return row.member ? (
    //           <div>
    
    //           { row&& <b>{row.member.name}</b>}<br/>
            
    //             </div>
    //         ) : (
    //           <div> </div>
    //         );
    //       },
    //   },
      {
        title: "UPI ID",
        dataIndex: "upi",
      key: "upi",
      },
     
      {
        title: "Transaction Date",
        
        render: (row) => {
            return(
              <>
              {row && <div>{moment(row.transactionDate).format("DD-MM-yyyy")}</div>}
              </>
            );
          },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
   
  ];
  const columns2 = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    // {
    //     title: "Name",
    //     render: (row) => {
    //         return row.member ? (
    //           <div>
    
    //           { row&& <b>{row.member.name}</b>}<br/>
            
    //             </div>
    //         ) : (
    //           <div> </div>
    //         );
    //       },
    //   },
      // {
      //   title: "UPI ID",
      //   render: (row) => {
      //       return row.upiId ? (
      //         <div>
    
      //         { row&& <b>{row.upiId.name}</b>}<br/>
            
      //           </div>
      //       ) : (
      //         <div> </div>
      //       );
      //     },
      // },
     
      {
        title: "Transaction Date",
        
        render: (row) => {
            return(
              <>
              {row && <div>{moment(row.transactionDate).format("DD-MM-yyyy")}</div>}
              </>
            );
          },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
     
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
    
  ];
  const onHistory = (e) =>{
if(e==="Withdraw"){
  setShowT(1)
}
else{
  if(e==="Recharge"){
    setShowT(2)
  }
  else{
    setShowT(3)
  }
}
  }
  return (

    <div className=" realludokingsize" >
      
    <div>
<Container>
<div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start">
          <a href="#/HomeScreen">
            <button className="btn btn-primary border">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="1em"
                height="1em"
                fill="currentColor"
                className="me-2">
                <path
                  fillRule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
              <span className="text-capitalize">Back</span>
            </button>
          </a>
        </div>
       
      </div>
<div className="d-flex align-items-center justify-content-evenly overflow-auto pt-3 px-0 container">
      <Button className="text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill text-white bg-primary" style={{ cursor: 'pointer' }} onClick={()=>onHistory("Withdraw")}>Withdraw</Button>
      <Button className="text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill text-white bg-primary" style={{ cursor: 'pointer' }} onClick={()=>onHistory("Recharge")}>Recharge</Button>
      <Button className="text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill text-white bg-primary" style={{ cursor: 'pointer' }} onClick={()=>onHistory("Game")}>Game</Button>
    </div>
{ShowT === 2 && <form className="d-flex ms-auto" >
<input
  type="text"
  className="form-control me-2 mb-2"
  placeholder="Enter UTR number"
  pattern="^\d{8}|\d{12}$"
  required
  minLength={8}
  maxLength={12}
  value=""
/>
<button className="btn btn-primary mb-2" type="submit" disabled>
  Search
</button>
</form>}
{/* {ShowT === 3 && <form className="d-flex ms-auto" >
<input
  type="text"
  className="form-control me-2 mb-2"
  placeholder="Enter Room code "
  pattern="^\d{8}|\d{12}$"
  required
  minLength={8}
  maxLength={12}
  value=""
/>
<button className="btn btn-primary mb-2" type="submit" disabled>
  Search
</button>
</form>} */}
{ShowT===3 && gameData === null && <div classname>
<div>
<img src={trns} alt="no history" className="w-100" />
</div>
</div> }
 <div className="master-snf-screen container" >
      <div>
       {ShowT===1 && <Table columns={columns1} dataSource={PaymentData1} />}
        {ShowT===2 && <Table columns={columns2} dataSource={PaymentData2} />}
        {ShowT===3 && <Table columns={columns3} dataSource={gameData} />}
      
      </div>
      
    </div>
</Container>
</div>
    </div>
  );
};

export default History;