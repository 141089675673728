import React, { useContext,useEffect,useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import ludo from "../../images/ludo/ludoavt.svg"
import winimg from "../../images/ludo/winimg.webp"
import { Button } from "react-bootstrap";
import axiosInstance from "../../axiosInstance";
const Profile = (props) => {


  const user = useContext(UserContext);
  let navigate = useNavigate();
const [userData , setUserData] = useState(null);
const [walletData , setWalletData] = useState(null);
const [gameData , setGameData] = useState(null);

  useEffect(() => {
    
    let mounted = true;
    if (mounted) {

   
      if (user.userId == null) {
       window.location.reload(true);
      }

      
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getWallet();
    getUser();
    getGame();
  }
  const  getUser = () =>{
    axiosInstance.get(`/member/${user.userId}`).then((res)=>{
      console.log("member",res.data.data)
      setUserData(res.data.data);
    })
  } 
  const  getWallet = () =>{
    axiosInstance.get(`/wallet/${user.userId}`).then((res)=>{
      console.log("member",res.data.data)
      setWalletData(res.data.data);
    })
  } 
  var gameC = []
var getG = 1 
  const getGame = () =>{
    getG = getG +1
   if(getG === 2) {
    axiosInstance.get(`/game/runing4/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
      console.log("length",response.data.data.length)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
      getGame2()
    }
    else{
      getGame3();
     }
    });
    
   }
  }
var getG2 = 1 

  const getGame2 = () =>{
    getG2 = getG2 +1
   if(getG2 === 2) {
    axiosInstance.get(`/game/runing5/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
      getGame3();
      }
      else{
        getGame3();
       }
    });

   }
  }
  var getG3 = 1 

  const getGame3 = () =>{
    getG3 = getG3 +1
   if(getG3 === 2) {
    axiosInstance.get(`/game2/runing4/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
        const sortedData = [...response.data.data].sort(
          (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
        );
        gameC.push(...sortedData)
        getGame4();
      }
     else{
      getGame4();
     }

    });
    
   }
  }
  var getG4 = 1 

  const getGame4 = () =>{
    getG4 = getG4 +1
   if(getG4 === 2) {
    axiosInstance.get(`/game2/runing5/${user.userId}`).then((response) => {
      console.log(response.data.data)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)

    });
    const sortedData = [...gameC].sort(
      (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
    );
    console.log("gameC222",sortedData.length)

    setGameData(sortedData.length)
   }
  }
  const handleLogout = () => {
    localStorage.clear();
    // navigate(`${environment.url.AUTH_URL}`, { replace: true });
    // navigate('/LoginScreen', { replace: true });
    // window.location.href=`${environment.url.AUTH_URL}`;
    window.location.href = `#/LoginScreen`;
  }   

  return (
   <div  className="realludokingsize"> 
   <div className="col-12 mx-auto g-0 iframe-sec p-3">
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark text-capitalize card-header">profile</div>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-center">
          <div style={{ height: 80, width: 80,}}><div class="bg-success rounded-circle position-relative shadow " style={{width:60, height:60,}}><img src="/static/media/ludoavt.808316712528b017c1b86dfc0bc3f922.svg" alt="Your Alt Text"/><div class="position-absolute shadow rounded-circle bg-white raj151" style={{width: 24, height: 24, bottom: 0, right: 0, cursor:PointerEvent,}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="black"  style={{width: 12,}}><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"></path></svg></div></div></div>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center mb-3">
            <label className="form-label text-capitalize">username</label>
            <div className="align-self-stretch d-flex align-items-center">
              {userData !== null && <input type="text" className="form-control me-2" maxLength="10" disabled value={userData.name} />}
              {/* <button className="btn btn-primary text-capitalize btn-sm align-self-stretch" style={{ width: 75 }}>edit</button> */}
            </div>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center mb-3">
            <label className="form-label text-capitalize">mobile number</label>
            <div className="align-self-stretch">
             {userData !== null &&  <input type="number" className="form-control" readOnly disabled value={userData.mobile} />}
            </div>
          </div>
   
        </div>
      </div>
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark card-header">Metrics</div>
        <div className="card-body">
      <div className="g-0 gx-2 mb-2 row">
        {/* First column */}
        <div className="col">
          <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
            <div className="text-capitalize text-start px-2 card-header" style={{ fontSize: '0.9rem' }}>
              <div className="hstack gap-1 rajuda">
              <img src={winimg} width="16px" height="16"  style={{marginRight:5,}} alt="games played" />
                <span>games played</span>
              </div>
            </div>
            <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">0.00</div>
          </div>
        </div>
        {/* Second column */}
        <div className="col">
          <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
            <div className="text-capitalize text-start px-2 card-header" style={{ fontSize: '0.9rem' }}>
              <div className="hstack gap-1 rajuda">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" style={{marginRight:5,}} height="16" fill="currentColor">
                  <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"></path>
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                  <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                </svg>
                <span>chips won</span>
              </div>
            </div>
            <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">0.00</div>
          </div>
        </div>
      </div>

      <div className="g-0 gx-2 row">
        {/* Third column */}
        <div className="col">
          <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
            <div className="text-capitalize text-start px-2 card-header" style={{ fontSize: '0.9rem' }}>
              <div className="hstack gap-1 rajuda">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" style={{marginRight:5,}} fill="currentColor">
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 2.5 0 0 0 0 5Z"></path>
                </svg>
                <span>referral earning</span>
              </div>
            </div>
            <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">0.00</div>
          </div>
        </div>

        {/* Fourth column */}
        <div className="col">
        <div className="d-flex flex-column align-items-stretch justify-content-start h-100 w-100 card">
          <div className="text-capitalize text-start px-2 card-header" style={{ fontSize: '0.9rem' }}>
            <div className="hstack gap-1 rajuda">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" style={{marginRight:5,}} fill="currentColor">
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
              </svg>
              <span>Penalty</span>
            </div>
          </div>
          <div className="fs-5 fw-semibold text-start py-1 px-2 card-body">0.00</div>
        </div>
      </div>
    </div>
        </div>
      <div className="d-grid py-2 card-body">
        <button type="button" className="text-capitalize btn btn-outline-danger" onClick={handleLogout}>logout</button>
      </div>
    </div>
    </div>
 </div>
     );
};

export default Profile;

