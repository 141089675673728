import React, { useContext, useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Button } from "react-bootstrap";
import { message } from "antd";
import axiosInstance from "../../axiosInstance";
const Withdraw = (props) => {


  const user = useContext(UserContext);
  let navigate = useNavigate();

const [upi , setUpi] = useState()
const [upiR , setUpiR] = useState()
const [payment , setPayment] = useState()
const [walletAmount , setWalletAmount] = useState()
const [wwalletAmount , setWWalletAmount] = useState(null)

  useEffect(() => {
    let mounted = true;
    if (mounted) {


      if (user.userId == null) {
        window.location.reload(true);
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getPayment();
  }
  const getPayment = () => {
    axiosInstance.get(`wallet/${user.userId}`).then((response) => {
      
    setWalletAmount(response.data.data.winningAmount+response.data.data.depositeAmount);
    setWWalletAmount(response.data.data.winningAmount)
    });
  };

  const onWithdraw = () => {
    if(payment>99){
      if(payment<wwalletAmount){
        const data = {
          userId:user.memberId,
          member:user.userId,
          amount:payment,
          upi:upi,
          status:"requested",
          transactionDate:new Date()
        };
        console.log("hii")
      
         if(data.userId!==undefined  && data.member!==undefined&& data.amount!==undefined&& data.status!==undefined && data.upi!==undefined&& data.upi!==''&& data.status!==''&& data.amount!==''&& data.member!==''&& data.userId!==''){
          axiosInstance.post("/withdraw", data).then((res) => {
            if (res.data && res.data.responseCode === -1) {
              message.error("Record Already Exists");
            } else if (res.data && res.data.responseCode === 1) {
              console.log("hii")
              const data2 = {
                winningAmount : wwalletAmount-payment
             }
             console.log("winningAmount",data2.winningAmount)

            axiosInstance.put(`/wallet/${user.userId}` , data2).then((response) => {
                console.log(response.data.data)
              });
              message.success("Record saved successfully");
              navigate(`/Wallet`, { replace: true });
            } else message.error("Something wrong. Please try again...!");
          });
       }
       else{
        message.error("Please fill out all required fields. And submit again...!");
       }
      }
      else{
        message.error(" Insuficiant Chips, Please re enter valid Chips. And submit again...!");
        navigate(`/Wallet`, { replace: true });
      }
    }
 else{
  message.error(" You can withdraw only Rs 100 or amount above Rs 100....!");
 }
   
  };

  return (

   <div className="realludokingsize">
     <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
    <div class="offcanvas-header">
  <div class="offcanvas-title h5"id="offcanvasBottomLabel">How To Play Games &amp; Earn?</div>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body small">
   Your Video
    </div>
  </div>
     <div className="col-12 mx-auto g-0 iframe-sec p-3 realludokingsize">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div>
          <a href="#/Wallet">
            <button className="btn btn-primary">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-2">
                <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
              <span className="text-capitalize">Back</span>
            </button>
          </a>
        </div>
        <div>
          <button type="button" className="d-flex align-items-center btn btn-outline-primary btn-md"data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-1">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
            </svg>
            <span className="text-capitalize">guide</span>
          </button>
        </div>
      </div>

      <div className="mb-3 shadow card">
        <div className="bg-light text-dark card-header">Payment Mode</div>
        <div className="bg-secondary text-center py-1">
          {wwalletAmount !== null && <span className="text-white text-center" style={{ fontSize: '0.8rem' }}>Withdrawal Chips: {wwalletAmount}</span>}
          {wwalletAmount === null && <span className="text-white text-center" style={{ fontSize: '0.8rem' }}>Withdrawal Chips: 0.00</span>}
        </div>
        <div className="card-body">
          <div className="d-flex flex-row align-items-center justify-content-between mb-1">
            <span style={{ fontSize: '0.8rem' }}>Minimum: 100</span>
            <span style={{ fontSize: '0.8rem' }}>Maximum: 1,00,000</span>
          </div>
        </div>
      </div>

      <div className="mb-3 shadow card">
        <div className="bg-light text-dark card-header">Payment Details</div>
        <div className="card-body">
            <div className="vstack gap-3">
              <div>
                <label className="text-capitalize text-start w-100 form-label" htmlFor="upiFormAccountNumber">UPI ID</label>
                <input placeholder="Your UPI ID" pattern="^[\w.+-]+\@[\w-]+(?!\w*\.[\w]+)$" type="text" id="upiFormAccountNumber" className="form-control form-control" onChange={(e)=>{setUpi(e.target.value)}} />
              </div>
              <div>
                <label className="text-capitalize text-start w-100 form-label" htmlFor="upiFormReEnterAccountNumber" >Re Enter UPI ID</label>
                <input placeholder="Re Enter Your UPI ID" pattern="^[\w.+-]+\@[\w-]+(?!\w*\.[\w]+)$" type="text" id="upiFormReEnterAccountNumber" className="form-control form-control" onChange={(e)=>{setUpiR(e.target.value)}} />
              </div>
              <div>
                <label className="text-capitalize text-start w-100 form-label" htmlFor="upiFormChips">Chips</label>
                <input placeholder="Chips" min="95" max="100000" type="number" id="upiFormChips" className="form-control form-control" onChange={(e)=>{setPayment(e.target.value)}} />
              </div>
             
            </div>
            <div>
            <button  disabled="" className="btn btn-primary mt-3" onClick={()=>onWithdraw()}>Submit</button>

            </div>
        </div>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <ul className="list-group"></ul>
      </div>
    </div>
   </div>
  );
};

export default Withdraw;