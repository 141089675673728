import React, { useState, useContext, useEffect } from "react";
import { Col, Form, Row, Input, Button, message, Modal, Image } from "antd";
import { EyeInvisibleOutlined, EyeOutlined, EyeTwoTone } from "@ant-design/icons";
import axiosInstance from "../axiosInstance";
import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';
// import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {Layout, Menu, Dropdown} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
// import {environment} from './Environment';
// import logo from '../src/images/logo.jpg';
import Icon from '@ant-design/icons';
import Hamburger from 'hamburger-react';
// import {useState} from 'react';
import manue from '../static/media/menu.png'
import imglogo from "../static/media/Realludokinglogo.png"
import wollet from "../static/media/wallet.png"

import ForgotPasswordModel from "./ForgotPassword.model";
import { Container } from "react-bootstrap";
import { Nav2 } from "../Nav2";

const LoginScreen = () => {
  let navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useContext(UserContext);
  useEffect(() => {

    let mounted = true;
    if (mounted) {

      
      if (user.userId !== null) {
        // navigate("/HomeScreen", { replace: true });
        localStorage.clear();
        window.location.reload();
      }


    }
    return () => (mounted = false);
  }, []);

  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAdd = () => {
    setIsModalOpen(false);
  };

  const onSignin = () => {
  

    axiosInstance
      .post("/login", { mobile: mobile, password: password })
      .then((res) => {
       
        if (res.data) {
          if(res.data.responseCode === 1){
           
            localStorage.setItem("authtoken", JSON.stringify(res.data.auth));
 //navigate(`/HomeScreen`, { replace: true });
           window.location.href = "/";
  
          }
          if(res.data.responseCode === -2){
            message.error("Your account is temporarily frozen");
          }
          if(res.data.responseCode === -1){
            message.error("please enter correct mobile number or password");
          }

        } else message.error("please enter correct mobile number or password");
      })
      .catch((err) => {
        message.error("please enter correct mobile number or password");
      });
  };
  const onSignup = () => {
    navigate('/SignupScreen', { replace: true });
    // window.location.href= `/SignupScreen`;
  };

  const onForgotPassword = () => {
    setIsModalOpen(true);
    // navigate('/SSignupScreen', { replace: true });

  };


  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const onshowPassword = () => {
    setShowPassword(!showPassword);
  };

  
  return (
  
  <div className="japurLudoNav realludokingsize">
    <div class="fixed">
    <div class="bg-danger py-2 text-white w-100 text-center">Commission: 5% ◉ Referral: 2% For All Games</div>
    <div
      class="w-100 bg-white shadow-sm text-white py-2 pe-2 ps-2 d-flex d-sm-flex align-items-center align-items-sm-center justify-content-between justify-content-sm-between"
      >
      <div className='d-flex  align-items-center'>
        {/* <button type="button" class="bg-white border-0 btn btn-light"><svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16" width="24" height="24" fill="currentColor">
            <path fill-rule="evenodd"
              d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z">
            </path>
          </svg></button> */}
              <div className='ram01'>
          <img className='menuimg01' src={manue} alt="menuicon" />
        
         </div>
          <a class="text-decoration-none text-white fw-semibold fs-4" href="/"><span
            class="text-white"><img src={imglogo} alt="logo"
              height="40" className=' mainlogo'/></span></a></div>
      <div class="row">
        <div class="p-0 col"></div>
        <div class="col"><a class="text-decoration-none text-white " href="/">
            <div class="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-2"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="green"
                class="me-2">
                <path
                  d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z">
                </path>
                <path
                  d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z">
                </path>
              </svg><strong>0</strong></div>
          </a></div>
      </div>
    </div>
  </div>




  <div class="fixed 02">
    <div class="bgclr py-2 text-white w-100 text-center">Commission: 5% ◉ Referral: 2% For All Games</div>
    <div
      class="w-100 bg-white shadow-sm text-white py-2 pe-2 ps-2 d-flex d-sm-flex align-items-center align-items-sm-center justify-content-between justify-content-sm-between"
      >
      <div className='d-flex  align-items-center'>
        {/* <button type="button" class="bg-white border-0 btn btn-light"><svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16" width="24" height="24" fill="currentColor">
            <path fill-rule="evenodd"
              d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z">
            </path>
          </svg></button> */}
       
         <img className='menuimg01' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" src={manue} alt="menuicon" />



<div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
<div className="bg-dark offcanvas-header">
            <div className="text-white fw-bold offcanvas-title h5">
              Real Ludo King
            </div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="d-flex flex-column align-items-stretch justify-content-start p-0 offcanvas-body">
            <div className="d-flex align-items-center justify-content-between p-4">
              <div className="fs-1 fw-bold text-start d-flex align-items-center justify-content-start">
                <div className="hstack gap-2">
                  <div className="m-0 me-1 text-dark d-flex align-items-center justify-content-start">
                    <p className="m-0">Hey,</p>
                    <p className="m-0">User..</p>
                    {/* <p
                      className="text-truncate m-0 me-2"
                      style={{maxWidth: '125px'}}>
                      &nbsp;
                    </p> */}
                    <div><a href="/"><div  class="rounded-circle"><img width="40" height="40" src="./static/media/avatar-f-2.c30ca059e863004ac5f7e22dcb211721.svg" alt="avatar"/></div></a></div>
                  
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-stretch justify-content-start">
              <a
                className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"
                href="/" data-bs-dismiss="offcanvas" aria-label="Close">
                <div className="d-flex align-items-center justify-content-start">
                  <div className="hstack gap-3">
                    <p className="p-0 m-0 text-capitalize">play</p>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-0 p-0 d-flex align-items-center justify-content-center">
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
              </a>
              <a
                className="text-start text-decoration-none bg-white p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"
                href="/" data-bs-dismiss="offcanvas" aria-label="Close"
              >
                <div className="d-flex align-items-center justify-content-start">
                  <div className="hstack gap-3">
                    <p className="p-0 m-0 text-capitalize">Wallet</p>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-0 p-0 d-flex align-items-center justify-content-center">
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
              </a>
              <a data-bs-dismiss="offcanvas" aria-label="Close"
                className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"
                href="/"
                >
                <div className="d-flex align-items-center justify-content-start">
                  <div className="hstack gap-3">
                    <p className="p-0 m-0 text-capitalize">History</p>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-0 p-0 d-flex align-items-center justify-content-center">
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
              </a>
              <a data-bs-dismiss="offcanvas" aria-label="Close"
                className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"
                href="/"
          >
                <div className="d-flex align-items-center justify-content-start">
                  <div className="hstack gap-3">
                    <p className="p-0 m-0 text-capitalize">Profile</p>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-0 p-0 d-flex align-items-center justify-content-center">
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
              </a>
              <a data-bs-dismiss="offcanvas" aria-label="Close"
                className="text-start text-decoration-none bg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"
                href="/"
                >
                <div className="d-flex align-items-center justify-content-start">
                  <div className="hstack gap-3">
                    <p className="p-0 m-0 text-capitalize">Refere & Earn</p>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-0 p-0 d-flex align-items-center justify-content-center">
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
              </a>
      
              <a data-bs-dismiss="offcanvas" aria-label="Close"
                className="text-start text-decoration-none bg-whitebg-light p-4 text-dark fs-2 text-capitalize d-flex align-items-center justify-content-between"
                href="/"
        >
                <div className="d-flex align-items-center justify-content-start">
                  <div className="hstack gap-3 rajuji">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="36"
                      height="36"
                      fill="currentColor">
                      <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                      <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                    </svg> */}
                    <p className="p-0 m-0 text-capitalize">legal terms</p>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  className="m-0 p-0 d-flex align-items-center justify-content-center">
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
              </a>
            </div>
          </div>
</div>

          <a class="text-decoration-none text-white fw-semibold fs-4" href="/"><span
            class="text-white"><img src={imglogo} alt="logo"
              height="45" className=' mainlogo'/></span></a></div>
      <div class="row">
        <div class="p-0 d-flex raju15"></div>
        <div class="col"><a class="text-decoration-none text-white " href="/">
            <div class="py-1 bg-white border px-2 text-dark d-flex align-items-center rounded-2">
          <img className='wllet' src={wollet} alt="" srcset="" />
            <strong>0</strong></div>
          </a></div>
      </div>
    </div>
  </div>






  

  

<div class="col mx-auto g-0 iframe-sec p-3"><div class="card"><div class="bg-light text-dark card-header">Login</div>
<div className=" gradient-form ">

<div>

  <div className="card-body">
    <div className="d-flex flex-column ">

     

      <p>Please login to your account</p>


      <MDBInput wrapperClass='mb-4' maxLength={10}  placeholder='Mobile Number' onChange={(e) => setMobile(e.target.value)} id='form1' type='number'/>
      <MDBInput wrapperClass='mb-4'  placeholder='Password' onChange={(e) => setPassword(e.target.value)} id='form2' type={showPassword ? 'text' : 'password'}>
        <EyeOutlined className="showPassword 02" onClick={()=>onshowPassword()}/>
      </MDBInput>


      <div className="text-center pt-1 mb-2 pb-1">
       <div >
       <button className="mb-4 w-100 gradient-custom-2 btn btn-primary" onClick={onSignin}>Sign in</button>
       </div>
       <span  onClick={() => onForgotPassword()} style={{color:"#18aeff",textDecoration:"underline",}}>  Forgot password?</span>
      
        
      </div>

      <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
        <p className="mb-0">Don't have an account?</p>
        <div className="lcreate">
          <a href="#/SignupScreen ">Create account</a>
       
        </div>
      </div>

    </div>

  </div>

<div >
{isModalOpen && <ForgotPasswordModel
              isVisible={isModalOpen}
              onCancel={onCancel}
              onAdd={onAdd}
              setIsModalOpen={setIsModalOpen}
            />}
</div>

</div>

</div>
  </div></div>
 </div>
  );
};

export default LoginScreen;